import React, { Component } from 'react'
import { getUserSettings } from './api'
import {
  createHashRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  defer,
} from 'react-router-dom'
import Intercom from '@intercom/messenger-js-sdk';
import LogRocket from 'logrocket';


import './scss/style.scss'

// Containers
const AuthLayout = React.lazy(() => import('./layout/AuthLayout'))
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const getUserData = () =>
  new Promise((resolve) => {
    getUserSettings()
      .then((response) => {
        resolve(true)

        window.stripePk = response.data.stripePublicAPIKey

        LogRocket.init('jw1x7p/kitt-ai');
        LogRocket.identify(response.data.userId, {
          name: response.data.firstName + ' ' + response.data.lastName ,
          email: response.data.userEmail,

          // Add your own custom user variables here, ie:
          subscriptionType: 'pro'
        });

        
        Intercom({
          app_id: 'yav2ecqa',
          user_id: response.data.userEmail, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
          name: response.data.firstName + ' ' + response.data.lastName , // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
          email: response.data.userEmail, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
          created_at: response.data.createdDateEpoch, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
        });
      })
      .catch((error) => {
        console.log(error)
        resolve(false)
      })
  })

const router = createHashRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />} loader={() => defer({ authPromise: getUserData() })}>
      <Route exact path="/login" name="Login Page" element={<Login />} />
      {/*<Route exact path="/register" name="Register Page" element={<Register />} />*/}
      <Route exact path="/404" name="Page 404" element={<Page404 />} />
      {/*<Route exact path="/500" name="Page 500" element={<Page500 />} />*/}
      <Route path="*" name="Home" element={<DefaultLayout />} />
    </Route>,
  ),
)

class App extends Component {
  render() {
    return (
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    )
  }
}

export default App
