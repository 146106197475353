export const getSessionToken = () => {
  var sessionToken = document.cookie.match(/sessionToken=[^;]+/)
  if (sessionToken != null) {
    if (sessionToken instanceof Array) sessionToken = sessionToken[0].substring(13)
    else sessionToken = sessionToken.substring(13)
  }
  return sessionToken
}

export const getLinkedinSessionToken = () => {
  var sessionToken = document.cookie.match(/linkedinSessionToken=[^;]+/)
  if (sessionToken != null) {
    if (sessionToken instanceof Array) sessionToken = sessionToken[0].substring(21)
    else sessionToken = sessionToken.substring(21)
  }
  return sessionToken
}

export const alertError = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    alert(error.response.data.message)
  }
}

export const delay = (time) => {
    return new Promise(function (resolve) {
        setTimeout(resolve, time)
    });
}
