import http from './http'
import {
  API_JOBS_ENDPOINT,
  API_SCHEDULED_JOBS_ENDPOINT,
  API_JOBS_ROLLUP_ENDPOINT,
  API_JOB_CREATE_ENDPOINT,
  API_JOB_LEAD_SEARCH_ENDPOINT,
  API_FIND_EMAIL_ENDPOINT,
  API_VERIFY_EMAIL_ENDPOINT,
  API_CRM_VERIFY_EMAIL_ENDPOINT,
  API_BOTS_ENDPOINT,
  API_BOT_OPTIONS_ENDPOINT,
  API_BOT_PAUSE_ENDPOINT,
  API_PROXIES_ENDPOINT,
  API_PLAYBOOK_ENDPOINT,
  API_USER_ENDPOINT,
  API_USER_SETTINGS_ENDPOINT,
  API_PAYMENT_METHOD_ENDPOINT,
  API_CREDIT_ENDPOINT,
  API_AUTO_RECHARGE_ENDPOINT,
  API_SUBSCRIPTION_ENDPOINT,
  API_APIKEY_ENDPOINT,
  API_LPDEFENDER_ENDPOINT,
  API_INVOICES_ENDPOINT,
  API_INVOICE_DOWNLOAD_ENDPOINT,
  API_INTEGRATIONS_ENDPOINT, 
  API_VERIFY_EMAIL_CSV_ENDPOINT, 
  API_FIND_EMAIL_CSV_ENDPOINT,
  API_ACTIVITY_REQUESTSPERDAY_ENDPOINT,
  API_ACTIVITY_FINDEMAILSUCCESSPERCENTAGE_ENDPOINT,
  API_ACTIVITY_VERIFYEMAILOUTCOMES_ENDPOINT,
  API_PAYMENT_NOTIFICATIONSETTINGS_ENDPOINT,
  API_ACTIVITY_JOBCOST_ENDPOINT,
  API_ACTIVITY_EXECUTIONTIME_ENDPOINT,
  API_ACTIVITY_QUEUEDTIME_ENDPOINT,
  API_DELIVERY_MACHINE_BETA_ENDPOINT

} from './_constants'


export const getReportsActivityByDay = async (params = {}) => {
  const response = await http.get(API_ACTIVITY_REQUESTSPERDAY_ENDPOINT, {
    params: params,
  })
  return response.data
}

export const getReportsFindEmailSuccessPercentageByDay = async (params = {}) => {
  const response = await http.get(API_ACTIVITY_FINDEMAILSUCCESSPERCENTAGE_ENDPOINT, {
    params: params,
  })
  return response.data
}

export const getReportsVerifyEmailOutcomesByDay= async (params = {}) => {
  const response = await http.get(API_ACTIVITY_VERIFYEMAILOUTCOMES_ENDPOINT, {
    params: params,
  })
  return response.data
}

export const getReportsJobCostByDay= async (params = {}) => {
  const response = await http.get(API_ACTIVITY_JOBCOST_ENDPOINT, {
    params: params,
  })
  return response.data
}

export const getReportsExecutionTime= async (params = {}) => {
  const response = await http.get(API_ACTIVITY_EXECUTIONTIME_ENDPOINT, {
    params: params,
  })
  return response.data
}

export const getReportsQueuedTime= async (params = {}) => {
  const response = await http.get(API_ACTIVITY_QUEUEDTIME_ENDPOINT, {
    params: params,
  })
  return response.data
}


export const getJobs = async (params = {}) => {
  const response = await http.get(API_JOBS_ENDPOINT, {
    params: params,
  })
  return response.data
}

export const getScheduledJobs = async (params = {}) => {
  const response = await http.get(API_SCHEDULED_JOBS_ENDPOINT, {
    params: params,
  })
  return response.data
}

export const getJobRollup = async (params = {}) => {
  const response = await http.get(API_JOBS_ROLLUP_ENDPOINT)
  return response.data
}

export const createJob = async (data) => {
  const response = await http.post(API_JOB_CREATE_ENDPOINT, data)
  return response.data
}

export const createLeadSearchJob = async (data) => {
  const response = await http.post(API_JOB_LEAD_SEARCH_ENDPOINT, data)
  return response.data
}

export const findEmail = async (data) => {
  const response = await http.post(API_FIND_EMAIL_ENDPOINT, data)
  return response.data
}

export const verifyEmail = async (data) => {
  const response = await http.post(API_VERIFY_EMAIL_ENDPOINT, data)
  return response.data
}

export const verifyEmailCRM = async (data) => {
  const response = await http.post(API_CRM_VERIFY_EMAIL_ENDPOINT, data)
  return response.data
}

export const verifyEmailCSV = async (data) => {
  const response = await http.post(API_VERIFY_EMAIL_CSV_ENDPOINT, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export const findEmailCSV = async (data) => {
  const response = await http.post(API_FIND_EMAIL_CSV_ENDPOINT, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export const testVerifyEmailCRM = async (data, url) => {
  const response = await http.post(url, data)
  return response.data
}

export const editJob = async (data) => {
  const response = await http.put(API_JOBS_ENDPOINT, data)
  return response.data
}

export const auditJob = async (url) => {
  const response = await http.get(url)
  return response.data
}

export const deleteJob = async (id) => {
  const response = await http.delete(API_JOBS_ENDPOINT, {
    data: { id: id },
  })
  return response.data
}

export const getBots = async () => {
  const response = await http.get(API_BOTS_ENDPOINT)
  return response.data
}

export const getBotOptions = async () => {
  const response = await http.get(API_BOT_OPTIONS_ENDPOINT)
  return response.data
}

export const createBot = async (data) => {
  const response = await http.post(API_BOTS_ENDPOINT, data)
  return response
}

export const editBot = async (data) => {
  const response = await http.put(API_BOTS_ENDPOINT, data)
  return response.data
}

export const deleteBot = async (id) => {
  const response = await http.delete(API_BOTS_ENDPOINT, {
    data: { id: id },
  })
  return response.data
}

export const pauseBot = async (id) => {
  const response = await http.put(
    API_BOT_PAUSE_ENDPOINT,
    {
      botId: id,
      pause: true,
    },
  )
  return response.data
}

export const unpauseBot = async (id) => {
  const response = await http.put(
    API_BOT_PAUSE_ENDPOINT,
    {
      botId: id,
      pause: false,
    },
  )
  return response.data
}

export const getProxies = async () => {
  const response = await http.get(API_PROXIES_ENDPOINT)
  return response.data
}

export const getPlaybooks = async () => {
  const response = await http.get(API_PLAYBOOK_ENDPOINT)
  return response.data
}

export const createPlaybook = async (data) => {
  const response = await http.post(API_PLAYBOOK_ENDPOINT, data)
  return response
}

export const editPlaybook = async (data) => {
  const response = await http.put(API_PLAYBOOK_ENDPOINT, data)
  return response.data
}

export const deletePlaybook = async (id) => {
  const response = await http.delete(API_PLAYBOOK_ENDPOINT, {
    data: { id: id },
  })
  return response.data
}

export const getUsers = async () => {
  const response = await http.get(API_USER_ENDPOINT)
  return response.data
}

export const createUser = async (data) => {
  const response = await http.post(API_USER_ENDPOINT, data)
  return response
}

export const editUser = async (data) => {
  const response = await http.put(API_USER_ENDPOINT, data)
  return response.data
}

export const deleteUser = async (id) => {
  const response = await http.delete(API_USER_ENDPOINT, {
    data: { id: id },
  })
  return response.data
}

export const getInvoices = async () => {
  const response = await http.get(API_INVOICES_ENDPOINT)
  return response.data
}

export const downloadInvoice = async (id) => {
  const url = `${API_INVOICE_DOWNLOAD_ENDPOINT}?id=${id}`;
  const response = await http.get(url)
  return response.data
}

export const getPaymentMethods = async () => {
  const response = await http.get(API_PAYMENT_METHOD_ENDPOINT)
  return response.data
}

export const addPaymentMethod = async (data) => {
  const response = await http.post(API_PAYMENT_METHOD_ENDPOINT, { stripeId: data.id })
  return response.data
}

export const deletePaymentMethod = async (id) => {
  const response = await http.delete(API_PAYMENT_METHOD_ENDPOINT, {
    data: { id: id },
  })
  return response.data
}

export const getCredit = async () => {
  const response = await http.get(API_CREDIT_ENDPOINT)
  return response.data
}

export const addCredit = async (data) => {
  const response = await http.post(API_CREDIT_ENDPOINT, data)
  return response.data
}

export const getAutoRechargeSettings = async () => {
  const response = await http.get(API_AUTO_RECHARGE_ENDPOINT)
  return response.data
}

export const updateAutoRechargeSettings = async (data) => {
  const response = await http.post(API_AUTO_RECHARGE_ENDPOINT, data)
  return response.data
}

export const deleteAutoRechargeSettings = async () => {
  const response = await http.delete(API_AUTO_RECHARGE_ENDPOINT, {})
  return response.data
}


export const getSubscription = async () => {
  const response = await http.get(API_SUBSCRIPTION_ENDPOINT)
  return response.data
}

export const setSubscription = async (data) => {
  const response = await http.post(API_SUBSCRIPTION_ENDPOINT, data)
  return response.data
}

export const getApiKey = async () => {
  const response = await http.get(API_APIKEY_ENDPOINT)
  return response.data
}

export const getIntegrations = async () => {
  const response = await http.get(API_INTEGRATIONS_ENDPOINT)
  return response.data
}

export const getIntegrationSchema = async (url) => {
  const response = await http.get(url)
  return response.data
}

export const getLPDefenderCode = async (params) => {
  const response = await http.get(API_LPDEFENDER_ENDPOINT, {
    params: params,
  })
  return response.data
}

export const getUserSettings = async () => {
  return await http.get(API_USER_SETTINGS_ENDPOINT)
}

export const getNotificationSettings = async () => {
  const response = await http.get(API_PAYMENT_NOTIFICATIONSETTINGS_ENDPOINT)
  return response.data
}

export const updateNotificationSettings = async (data) => {
  const response = await http.post(API_PAYMENT_NOTIFICATIONSETTINGS_ENDPOINT, data)
  return response.data
}

export const deleteNotificationSettings = async () => {
  const response = await http.delete(API_PAYMENT_NOTIFICATIONSETTINGS_ENDPOINT, {})
  return response.data
}

export const requestDeliveryMachineBeta = async () => {
  const response = await http.post(API_DELIVERY_MACHINE_BETA_ENDPOINT)
  return response.data
}