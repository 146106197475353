import axios from 'axios';
import { getSessionToken } from './utils';

let sessionToken = getSessionToken();
if (process.env.REACT_APP_ENV === 'local') {
	sessionToken = process.env.REACT_APP_LOCAL_API_KEY;
}

axios.defaults.headers.common['X-Truly-Session-Token'] = sessionToken;

// http.js
const http = axios.create();
export default http;